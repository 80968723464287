import React, { FC } from 'react';
import styled from 'styled-components';
import { Text } from 'components';
import StartNowButton from './StartNowButton';
import { mobile, tablet } from 'styles/breakpoints';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import resultsSvgs from 'utils/constants/resultsSvgs';

interface BookInsightsProps {
  title: string;
  includedCards: {
    svgImg: string;
    cardTitle: string;
    cardText: string;
  }[];
  onClick: () => void;
  loading?: boolean;
  listTitle: string;
  list: string[];
}

const BookInsights: FC<BookInsightsProps> = ({
  title,
  includedCards,
  listTitle,
  list,
  onClick,
  loading,
}) => {
  const renderSvgImage = (svgKey: string) => {
    const Svg = resultsSvgs[svgKey];
    return <Svg />;
  };
  return (
    <Container>
      <InnerContainer>
        <Title>{title}</Title>
        <CardContainer>
          {includedCards.map((item, index) => (
            <Card key={index}>
              <SvgContainer>{renderSvgImage(item.svgImg)}</SvgContainer>
              <InnerCard>
                <CardTitle>{item.cardTitle}</CardTitle>
                <TextStyled>{item.cardText}</TextStyled>
              </InnerCard>
            </Card>
          ))}
        </CardContainer>
        <Title>{listTitle}</Title>
        <List>
          {list.map((item, index) => (
            <ListItem key={index}>
              <SvgList>{renderSvgImage('star')}</SvgList>
              {item}
            </ListItem>
          ))}
        </List>
        <Button onClick={onClick} loading={loading}>
          Order now
        </Button>
      </InnerContainer>
    </Container>
  );
};

export default BookInsights;

const Button = styled(StartNowButton)`
  width: 100%;
  height: 3.5rem;
  margin-top: 1.5rem;
  @media ${tablet} {
    height: 3rem;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin-bottom: 2.375rem;
  @media ${tablet} {
    margin-bottom: 1.5rem;
  }
`;

const Card = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

const InnerCard = styled.div`
  display: flex;
  flex-direction: column;
`;

const SvgContainer = styled.div`
  width: 2.5rem;
  height: 2.5rem;
`;

const CardTitle = styled.p`
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: normal;
  @media ${tablet} {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
  }
`;

const TextStyled = styled.p`
  color: #4d4d4d;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  @media ${tablet} {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const Title = styled(Text)`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
  width: 100%;
  margin-bottom: 1.5rem;
  @media ${tablet} {
    margin-bottom: 1rem;
  }
`;

const List = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  @media ${tablet} {
  }
`;

const SvgList = styled.div`
  width: 1.625rem;
  height: 1.625rem;
`;

const ListItem = styled.div`
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  @media ${tablet} {
    font-size: 0.875rem;
  }
`;

const Container = styled.div`
  background: #f3f3f8;
`;

const InnerContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 28.75rem;
`;
