import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import { Text } from 'components';
import Bubble from 'assets/icons/results/bubble.svg';
import { Style } from '@material-ui/icons';
import { tablet } from 'styles/breakpoints';

interface ChartProps {
  title: string;
  percent: number;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  width: 100%;
`;

const Title = styled(Text)`
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.00963rem;
`;

const Inner = styled.div`
  display: flex;
  padding: 0.36781rem 0rem;
  justify-content: center;
  align-items: center;
  gap: 0.07644rem;
  position: relative;
`;

const InnerItem = styled.div<{ bgColor: string }>`
  max-width: 3.36306rem;
  width: 100%;
  height: 0.76431rem;
  background-color: ${({ bgColor }) => bgColor};
  &:first-child {
    border-radius: 7.64331rem 0rem 0rem 7.64331rem;
  }
  :nth-last-child(2) {
    border-radius: 0rem 7.64331rem 7.64331rem 0rem;
  }
`;

const BubbleStyled = styled(Bubble)<{ percent: number }>`
  position: absolute;
  left: ${({ percent }) => `calc(${percent}% - 7px)`};
  transition: left 0.001s linear;
`;

const Labels = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Label = styled.p`
  color: #8e909a;
  text-align: center;
  font-size: 0.6875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.03363rem;
`;

const Chart: FC<ChartProps> = ({ title, percent }) => (
  <Container>
    <Title>{title}</Title>
    <Inner>
      <InnerItem bgColor="#F45158" />
      <InnerItem bgColor="#F45158" />
      <InnerItem bgColor="#F7C800" />
      <InnerItem bgColor="#F7C800" />
      <InnerItem bgColor="#F7C800" />
      <InnerItem bgColor="#3EA74A" />
      <InnerItem bgColor="#3EA74A" />
      <BubbleStyled percent={percent} />
    </Inner>
    <Labels>
      <Label>Weak</Label>
      <Label>Medium</Label>
      <Label>Strong</Label>
    </Labels>
  </Container>
);

export default Chart;
