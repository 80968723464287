import arrows from 'assets/icons/results/arrows.svg';
import briefcase from 'assets/icons/results/briefcase.svg';
import loveMessage from 'assets/icons/results/love-message.svg';
import star from 'assets/icons/results/star.svg';
import zodiac from 'assets/icons/results/zodiac.svg';
import aries from 'assets/icons/results/aries.svg';
import taurus from 'assets/icons/results/taurus.svg';
import gemini from 'assets/icons/results/gemini.svg';
import cancer from 'assets/icons/results/cancer.svg';
import leo from 'assets/icons/results/leo.svg';
import virgo from 'assets/icons/results/virgo.svg';
import libra from 'assets/icons/results/libra.svg';
import scorpio from 'assets/icons/results/scorpio.svg';
import sagittarius from 'assets/icons/results/sagittarius.svg';
import capricorn from 'assets/icons/results/capricorn.svg';
import aquarius from 'assets/icons/results/aquarius.svg';
import pisces from 'assets/icons/results/pisces.svg';
import ariesSign from 'assets/icons/results/aries_sign.svg';
import taurusSign from 'assets/icons/results/taurus_sign.svg';
import geminiSign from 'assets/icons/results/gemini_sign.svg';
import cancerSign from 'assets/icons/results/cancer_sign.svg';
import leoSign from 'assets/icons/results/leo_sign.svg';
import virgoSign from 'assets/icons/results/virgo_sign.svg';
import libraSign from 'assets/icons/results/libra_sign.svg';
import scorpioSign from 'assets/icons/results/scorpio_sign.svg';
import sagittariusSign from 'assets/icons/results/sagittarius_sign.svg';
import capricornSign from 'assets/icons/results/capricorn_sign.svg';
import aquariusSign from 'assets/icons/results/aquarius_sign.svg';
import piscesSign from 'assets/icons/results/pisces_sign.svg';
import calendar from 'assets/icons/results/calendar.svg';
import air from 'assets/icons/results/air.svg';
import water from 'assets/icons/results/water.svg';
import earth from 'assets/icons/results/earth.svg';
import fire from 'assets/icons/results/fire.svg';
import male from 'assets/icons/results/male.svg';
import female from 'assets/icons/results/female.svg';
import other from 'assets/icons/results/other.svg';
import increase from 'assets/icons/results/increase.svg';
import clock from 'assets/icons/results/clock.svg';

export default {
  arrows,
  briefcase,
  loveMessage,
  star,
  zodiac,
  aries,
  taurus,
  gemini,
  cancer,
  leo,
  virgo,
  libra,
  scorpio,
  sagittarius,
  capricorn,
  aquarius,
  pisces,
  ariesSign,
  taurusSign,
  geminiSign,
  cancerSign,
  leoSign,
  virgoSign,
  libraSign,
  scorpioSign,
  sagittariusSign,
  capricornSign,
  aquariusSign,
  piscesSign,
  calendar,
  air,
  water,
  earth,
  fire,
  male,
  female,
  other,
  increase,
  clock,
};
