import React from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import { DynamicImage } from 'components';
import resultsSvgs from 'utils/constants/resultsSvgs';
import Button from './StartNowButton';

interface ImageSectionProps {
  image: string;
  title: string;
  subtitle: string;
  buttonText: string;
  onClick: () => void;
  loading?: boolean;
}

const Container = styled.div<{ bgHex?: string }>`
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 3rem 1rem;
  max-with: 28.75rem;
  width: 100%;
  @media ${tablet} {
    padding: 2.25rem 1rem;
  }
`;

const Card = styled.div`
  display: flex;
  gap: 0.25rem;
  max-width: 28.75rem;
  width: 100%;
  padding: 2.34694rem 2rem;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.67056rem;
  background: #f3f3f8;
  @media ${tablet} {
    padding: 1.75rem 0.688rem;
  }
`;

const Title = styled.div`
  color: #f7c800;
  text-align: center;
  font-size: 4.02331rem;
  font-style: normal;
  font-weight: 700;
  line-height: 5.02913rem;
  @media ${tablet} {
    font-size: 2.5rem;
    line-height: 3.75rem;
  }
`;

const Subtitle = styled.p`
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media ${tablet} {
    font-size: 0.875rem;
  }
`;

const ImgContainer = styled.div`
  max-width: 18.5rem;
  width: 100%;
  @media ${tablet} {
    max-width: 17.8rem;
  }
`;

const ButtonStyled = styled(Button)`
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 100px;
  width: 100%;
  max-width: 28.75rem;
  margin-top: 1rem;
  height: 3.5rem;
  @media ${tablet} {
    height: 3rem;
  }
`;

const ImageSection: FC<ImageSectionProps> = ({
  image,
  title,
  subtitle,
  buttonText,
  onClick,
  loading,
}) => (
  <Container>
    <Card>
      <ImgContainer>
        <DynamicImage src={image} alt="Nordastro app users" />
      </ImgContainer>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </Card>
    <ButtonStyled onClick={onClick} loading={loading}>
      {buttonText}
    </ButtonStyled>
  </Container>
);
export default ImageSection;
