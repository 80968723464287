import React, { FC, useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Headline, PrimaryButton } from 'components';
import { tablet, useQuery } from 'styles/breakpoints';
import { useRouter } from 'apis/history';
import { usePageView, useQuizData } from 'utils/hooks';
import { AppState } from 'state/types';
import IconList from './components/IconList';
import 'swiper/css';
import ImageSection from 'pages/start/checkout/components/ImageSection';
import 'swiper/css/pagination';
import StartButton from './components/StartNowButton';
import BookInsights from './components/BookInsights';
import TextImageCardButton from './components/TextImageCardButton';
import theme from 'utils/theme';
import OurPromise from './components/OurPromise';
import Layout from 'layouts/Layout';
import Tracking from 'utils/tracking';
import BookReviewResults from './components/BookReviewResults';
import AppSection from './components/AppSection';
import Disclaimer from 'components/Disclaimer';
import CardSection from './components/CardSection';
import PersonalSummary from './components/PersonalSummary';
import { normalizeStates } from 'utils/localization';

const Button = styled(PrimaryButton).attrs({
  cornerVariant: 'round',
})`
  background: ${({ theme }) => theme.colors.primary};
  padding: 0.75rem 1.5rem;
  min-width: 10.22rem;
  max-width: 15rem;
  div {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  @media ${tablet} {
    min-width: 7.06rem;
    min-height: unset;
  }
`;

const StartNow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 0;
`;

const ContentWrapper = styled.div<{ $padding?: string; $bgColor?: string }>`
  background-color: ${({ $bgColor, theme }) => $bgColor || theme.colors.light0};
  padding: ${({ $padding }) => $padding || 0};
`;
const CenterContent = styled.div<{
  $width?: string;
  $maxWidth: string;
  $padding?: string;
}>`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: ${({ $width }) => $width || 'auto'};
  max-width: ${({ $maxWidth }) => $maxWidth || 'auto'};
  padding: ${({ $padding }) => $padding || 0};
`;

const Container = styled.div`
  padding: 3rem 1rem;
  background: #f3f3f8;
  @media ${tablet} {
    padding: 2.25rem 1rem;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  height: 2.625rem;
  padding: 0.75rem 1.5rem !important;
  justify-content: center;
  align-items: center;
  max-width: 9.0625rem;
  width: 100%;
  min-width: unset;
  min-height: unset !important;
  text-wrap: nowrap !important;

  div {
    text-wrap: nowrap !important;
    line-height: normal !important;
  }
`;

const Results: FC<RouteComponentProps> = ({ location }) => {
  const config = useSelector((state: AppState) => state.config);
  const user = useSelector((state: AppState) => state.user);
  const [loading, setLoading] = useState<{ [key: string]: boolean }>({});
  const variant = config?.variant;
  const quizData = useQuizData('results');
  const { goToEmail, goToCheckout } = useRouter();
  const { isTablet } = useQuery();
  const quiz_flow_answers = useSelector(
    (state: AppState) => state.user?.quiz_flow_answers,
  );
  const getQuestionKey = (answersKeys: Set<string>[], sectionsKeys: any) => {
    const temp = new Set(sectionsKeys);

    for (const element of temp) {
      const parts = element.split('_');
      if (answersKeys.every(key => parts.includes(key))) {
        return element;
      }
    }

    return -1;
  };

  useEffect(() => {
    window?.scroll(0, 0);
  }, [window.location.pathname]);

  const resultKeys = quizData
    ? Object.keys(quizData).filter(key => Array.isArray(quizData[key]))
    : [];

  const sectionKey = getQuestionKey(quiz_flow_answers, resultKeys);
  usePageView({
    client_code: user.code,
    city: encodeURIComponent(
      user.geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    country: user.geolocation?.iso_country?.toLowerCase(),
    state: normalizeStates(
      user.geolocation?.iso_country || '',
      user.geolocation?.iso_state || '',
    ),
    gender: user.quiz_answers?.gender ? user.quiz_answers?.gender[0] : null,
    email: user.user?.email,
  });

  const handleContinue = (sectionKey: string) => {
    setLoading({ ...loading, [sectionKey]: true });
    if (location?.pathname) {
      Tracking.trackCTAButton(location.pathname);
    }
    if (variant === 'email-first') {
      return goToCheckout({ discount: false });
    }
    goToEmail();
  };

  const renderSection = (item: any, index: number) => {
    const key = `${item.type}-${index}`;

    switch (item.type) {
      default: {
        return <React.Fragment key={key} />;
      }
      case 'section4': {
        return <IconList key={key} {...item} />;
      }
      case 'imageSection': {
        return (
          <ImageSection
            key={key}
            {...item}
            onClick={() => handleContinue(key)}
            loading={loading[key]}
          />
        );
      }
      case 'bookInsights': {
        return (
          <Container>
            <BookInsights
              key={key}
              {...item}
              onClick={() => handleContinue(key)}
              loading={loading[key]}
            />
          </Container>
        );
      }
      case 'cardSection': {
        return <CardSection key={key} {...item} />;
      }
      case 'textImageCardButton': {
        return (
          <TextImageCardButton
            key={key}
            {...item}
            onClick={() => handleContinue(key)}
            loading={loading[key]}
          />
        );
      }
      case 'appSection': {
        return <AppSection key={key} {...item} />;
      }
      case 'personal_summary': {
        return (
          <PersonalSummary
            key={key}
            {...item}
            onClick={() => handleContinue(key)}
            loading={loading[key]}
          />
        );
      }
      case 'book_review': {
        return (
          <BookReviewResults
            {...item}
            key={key}
            onClick={() => handleContinue(key)}
            loading={loading[key]}
          />
        );
      }
      case 'ourPromise': {
        return (
          <ContentWrapper
            key={key}
            $padding={isTablet ? '2.25rem 1rem' : '3rem 1rem'}
            $bgColor="#f3f3f8;"
          >
            <OurPromise key={key} {...item} onClick={handleContinue} />

            <CenterContent
              $width={isTablet ? '100%' : '560px'}
              $maxWidth="28.75rem"
              $padding={'0'}
            >
              <StartButton
                onClick={() => handleContinue(key)}
                color={'primary'}
                loading={loading[key]}
              >
                {item?.startBtnTitle || 'Get my book'}
              </StartButton>
            </CenterContent>
          </ContentWrapper>
        );
      }
      case 'disclaimer': {
        return <Disclaimer key={key} {...item} />;
      }
    }
  };

  if (!quizData) {
    return null;
  }

  return (
    <Layout
      logoVariant={'left'}
      bgColor="coldGrey"
      rightComponent={
        quizData?.navBarBtnTitle ? (
          <StyledButton onClick={handleContinue}>
            {quizData?.navBarBtnTitle}
          </StyledButton>
        ) : (
          <></>
        )
      }
      hideFooter
      hasDivider={true}
    >
      {!!quizData?.headlineTitle && (
        <Headline
          bodyText={quizData.headlineTitle}
          bgColor={theme.colors.novaPink}
        />
      )}

      {quizData?.sections?.map(renderSection)}
    </Layout>
  );
};

export default Results;
