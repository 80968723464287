import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';
import resultsSvgs from 'utils/constants/resultsSvgs';
import Chart from './Chart';
import Background from 'assets/icons/results/result_background.svg';
import StartButton from './StartNowButton';
import useQuizAnswersBirthDetails from 'utils/hooks/useQuizAnswersBirthDetails';

interface PersonalSummaryProps {
  type: string;
  title: string;
  birth_date: {
    title: string;
    svgImg: string;
  };
  zodiac: { title: string };
  element: {
    title: string;
    sign: {
      [key: string]: string;
    };
  };
  birth_time: {
    title: string;
    svgImg: string;
  };
  gender: {
    title: string;
  };
  infoSection: {
    svgImg: string;
    text: string;
  };
  chart: ChartItem[];
  buttonText: string;
  onClick: () => void;
  loading?: boolean;
}
interface ChartItem {
  title: string;
  percent: number;
}

const PersonalSummary: FC<PersonalSummaryProps> = ({
  title,
  birth_date,
  element,
  zodiac,
  gender,
  infoSection,
  chart,
  birth_time,
  buttonText,
  onClick,
  loading,
}) => {
  const quiz_answers = useSelector(
    (state: AppState) => state.user.quiz_answers,
  );

  const renderSvgImage = (svgKey: string) => {
    const Svg = resultsSvgs[svgKey];
    return <Svg />;
  };

  const getBirthDate = () => {
    const birthDate = quiz_answers?.birth_date as string;
    const resolvedDate = new Date(birthDate);
    const year = resolvedDate.getFullYear();
    const month = ('0' + (resolvedDate.getMonth() + 1)).slice(-2); // Add leading zero
    const day = ('0' + resolvedDate.getDate()).slice(-2); // Add leading zero

    const dateOnly = `${year}-${month}-${day}`;
    return dateOnly;
  };

  return (
    <>
      <Container>
        <Title>{title}</Title>
        <CardContainer>
          <InnerCard>
            <LeftCard>
              <Bubble>
                <Calendor>{renderSvgImage(birth_date.svgImg)}</Calendor>
              </Bubble>
              <Content>
                <InfoTitle>{birth_date.title}</InfoTitle>
                <InfoText>{getBirthDate()}</InfoText>
              </Content>
            </LeftCard>
            <LeftCard>
              <Bubble>
                <Sign>
                  {renderSvgImage(element.sign[quiz_answers.zodiacSign])}
                </Sign>
              </Bubble>
              <Content>
                <InfoTitle>{element.title}</InfoTitle>
                <InfoText>{element.sign[quiz_answers.zodiacSign]}</InfoText>
              </Content>
            </LeftCard>
          </InnerCard>
          <InnerCard>
            <Card>
              <Bubble>
                <Zodiac>
                  {renderSvgImage(`${quiz_answers?.zodiacSign}Sign`)}
                </Zodiac>
              </Bubble>
              <ContentRight>
                <InfoTitle>{zodiac.title}</InfoTitle>
                <InfoText>{quiz_answers.zodiacSign}</InfoText>
              </ContentRight>
            </Card>

            {quiz_answers?.birth_time ? (
              <Card>
                <Bubble>
                  <Time>{renderSvgImage(birth_time.svgImg)}</Time>
                </Bubble>
                <ContentRight>
                  <InfoTitle>{birth_time.title}</InfoTitle>
                  <TimeText>{`${quiz_answers?.birth_time.hours}:${quiz_answers?.birth_time.minutes} ${quiz_answers?.birth_time.ampm}`}</TimeText>
                </ContentRight>
              </Card>
            ) : (
              <Card>
                <Bubble>
                  <Gender>{renderSvgImage(quiz_answers?.gender)}</Gender>
                </Bubble>
                <ContentRight>
                  <InfoTitle>{gender.title}</InfoTitle>
                  <InfoText>{quiz_answers?.gender}</InfoText>
                </ContentRight>
              </Card>
            )}
          </InnerCard>
          <BackgroundStyled />
          <ZodiacSvg>{renderSvgImage(quiz_answers.zodiacSign)}</ZodiacSvg>
        </CardContainer>
        <Info>
          <Increase>{renderSvgImage(infoSection.svgImg)}</Increase>
          {infoSection.text}
        </Info>
        <ChartMain>
          {chart.map((item, index) => (
            <Chart key={index} title={item.title} percent={item.percent} />
          ))}
        </ChartMain>
        <Button onClick={onClick} loading={loading}>
          {buttonText}
        </Button>
      </Container>
    </>
  );
};

export default PersonalSummary;

const Title = styled.p`
  color: #000;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.25rem;
  margin-bottom: 2.375rem;
  @media ${tablet} {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 2.06rem;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 3rem 1rem;
  background: #f3f3f8;
  @media ${tablet} {
    padding: 2.25rem 1rem;
  }
`;

const BackgroundStyled = styled(Background)`
  position: absolute;
  min-width: 26.5rem;
  right: 50%;
  transform: translateX(50%);
  @media ${tablet} {
    min-width: 12.5rem;
  }
`;

const ZodiacSvg = styled.div`
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  min-width: 6.52944rem;
  height: 6.52944rem;
  @media ${tablet} {
  }
`;

const Bubble = styled.div`
  width: 4.28794rem;
  height: 4.28794rem;
  background: #f7c800;
  border: 1.596px solid rgba(247, 200, 0, 0.4);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${tablet} {
    width: 3.098rem;
    height: 3.098rem;
  }
`;

const Calendor = styled.div`
  width: 1.90313rem;
  height: 1.90313rem;
  svg {
    width: 1.90313rem;
    height: 1.90313rem;
  }
  @media ${tablet} {
    width: 1.375rem;
    height: 1.375rem;
    svg {
      width: 1.375rem;
      height: 1.375rem;
    }
  }
`;

const Sign = styled(Calendor)``;

const Gender = styled(Calendor)``;

const Time = styled(Calendor)``;

const Zodiac = styled.div`
  width: 2.07613rem;
  height: 2.07613rem;
  svg {
    width: 2.07613rem;
    height: 2.07613rem;
  }
  @media ${tablet} {
    width: 1.5rem;
    height: 1.5rem;
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`;

const InfoTitle = styled.p`
  color: #8e909a;
  font-size: 0.86506rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-wrap: nowrap;
  @media ${tablet} {
    font-size: 0.625rem;
  }
`;

const InfoText = styled.p`
  color: #000;
  text-align: center;
  font-size: 1.03806rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  text-wrap: nowrap;
  white-space: nowrap;
  @media ${tablet} {
    font-size: 0.75rem;
  }
`;

const TimeText = styled(InfoText)`
  text-wrap: nowrap;
  white-space: nowrap;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.21519rem;
  align-items: flex-end;
  @media ${tablet} {
    max-width: 4.0625rem;
    gap: 0.125rem;
  }
`;

const ContentRight = styled(Content)`
  align-items: flex-start;
`;

const Card = styled.div`
  display: flex;
  width: 10.64013rem;
  height: 4.49825rem;
  justify-content: flex-start;
  align-items: center;
  gap: 0.519rem;
  @media ${tablet} {
    width: 7.6875rem;
    height: 3.25rem;
    gap: 0.375rem;
  }
`;

const CardLeft = styled(Card)`
  justify-content: flex-end;
`;
const LeftCard = styled(Card)`
  flex-direction: row-reverse;
`;

const CardContainer = styled.div`
  max-width: 26.47rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${tablet} {
    max-width: 22.1rem;
  }
`;

const InnerCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 6.875rem;
  z-index: 4;
  @media ${tablet} {
    gap: 6rem;
  }
`;

const Info = styled.div`
  display: flex;
  height: 5rem;
  padding: 0.375rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.5rem;
  border: 1px solid #3ea74a;
  background: #fff;
  max-width: 28.75rem;
  width: 100%;
  margin-top: 3.44rem;
  @media ${tablet} {
    padding: 0.625rem 1rem;
    margin-top: 2.01rem;
  }
`;

const Increase = styled.div`
  width: 2.3125rem;
  height: 2.52056rem;
`;

const ChartMain = styled.div`
  display: flex;
  padding: 1.25rem 2.375rem;
  flex-direction: column;
  align-items: center;
  gap: 1.125rem;
  background: #fff;
  border-radius: 0.75rem;
  margin-top: 0.75rem;
  max-width: 28.75rem;
  width: 100%;
  @media ${tablet} {
    padding: 1.25rem 0.875rem;
  }
`;

const Button = styled(StartButton)`
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 100px;
  max-width: 28.75rem;
  width: 100%;
  margin-top: 1.5rem;
  @media ${tablet} {
    margin-top: 1rem;
  }
`;
