import React, { FC } from 'react';
import styled from 'styled-components';
import { Text } from 'components';
import StartNowButton from './StartNowButton';
import { mobile, tablet } from 'styles/breakpoints';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';

interface BookInsightsProps {
  title: string;
  cards: {
    title: string;
    subTitle: string;
    number: string;
  }[];
}

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  margin-top: 1.5rem;
  @media ${tablet} {
    margin-top: 1rem;
  }
`;

const Card = styled.div`
  display: flex;
  align-items: flex-start;
  border-radius: 0.75rem;
  background: #fff;
  min-height: 6rem;
  background: #fff;
  box-shadow: 0px 0.5px 0.5px 0px rgba(0, 0, 0, 0.15);
`;

const CardTitle = styled.p`
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media ${tablet} {
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }
`;

const TextStyled = styled.p`
  color: #4d4d4d;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  @media ${tablet} {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
  }
`;

const Title = styled(Text)`
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.25rem;
  @media ${tablet} {
    font-size: 1.5rem;
    font-style: normal;
    line-height: 2rem;
  }
`;

const Number = styled(Text)`
  display: flex;
  width: 7.375rem;
  padding: 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 0.75rem 0rem 0rem 0.75rem;
  background: #f7c800;
  color: #fff;
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 3.75rem;
  @media ${tablet} {
    width: 6rem;
  }
`;

const CardInner = styled.div`
  min-height: 6rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  padding: 1rem;
  width: 100%;
  border-radius: 0 0.75rem 0.75rem 0;
  background: #f3f3f8;
  @media ${tablet} {
    padding: 1rem 0.88rem;
  }
`;

const Container = styled.div`
  padding: 3rem 1rem;
  background: #fff;

  @media ${tablet} {
    padding: 2.25rem 1rem;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 28.75rem;
`;

const CardSection: FC<BookInsightsProps> = ({ title, cards }) => (
  <Container>
    <InnerContainer>
      <Title>{title}</Title>
      <CardContainer>
        {cards.map((item, index) => (
          <Card key={index}>
            <Number>{item.number}</Number>
            <CardInner>
              <CardTitle>{item.title}</CardTitle>
              <TextStyled>{item.subTitle}</TextStyled>
            </CardInner>
          </Card>
        ))}
      </CardContainer>
    </InnerContainer>
  </Container>
);

export default CardSection;
