import React, { FC, useState } from 'react';
import StartButton from './StartNowButton';
import styled from 'styled-components';
import { DynamicImage, Text } from 'components';
import { mobile, tablet } from 'styles/breakpoints';
import bookGif from 'assets/images/results/result_book.gif';
import Check from 'assets/icons/results/pink_checkmark.svg';
import { AppState } from 'state/types';
import { useSelector } from 'react-redux';

interface BookReviewResultsProps {
  title: string;
  image: string;
  listItem: string;
  buttonText: string;
  onClick: () => void;
  loading?: boolean;
}

const BookReviewResults: FC<BookReviewResultsProps> = ({
  title,
  loading,
  subtitle,
  buttonText,
  onClick,
}) => (
  <Container>
    <Title color="dark80" dangerouslySetInnerHTML={{ __html: title }} />
    <Subtitle>{subtitle}</Subtitle>
    <ImageWrapper />
    <Button onClick={onClick} loading={loading}>
      {buttonText}
    </Button>
  </Container>
);

export default BookReviewResults;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 30.75rem;
  width: 100%;
  margin: 0 auto;
  padding: 3rem 1rem;
  @media ${tablet} {
    padding: 2.25rem 1rem;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 28.75rem;
  width: 100%;
  background-image: url(${bookGif});
  background-attachment: fixed;
  height: 20.1108rem;
  background-size: 30rem;
  background-position: 50% -5rem;
  background-attachment: scroll;
  margin-bottom: 1.5rem;
  background-repeat: no-repeat;
  @media ${mobile} {
    background-size: 22rem;
    background-position: 50% -4rem;
    height: 15rem;
  }
`;

const Title = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 2.0625rem;
  margin-bottom: 0.5rem;
  @media ${tablet} {
    font-size: 1.5rem;
    line-height: 2rem;
  }
`;

const Subtitle = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
  @media ${tablet} {
    font-size: 0.875rem;
    line-height: normal;
    margin-bottom: 1rem;
  }
`;

const Button = styled(StartButton)`
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 100px;
  width: 100%;
  height: 3.5rem;
  @media ${tablet} {
    height: 3rem;
  }
`;
